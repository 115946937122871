import React from "react";
import './servicetwo.css';
import { Link } from "react-router-dom";
import Service1 from "../../assets/images/slider/slider5.webp";
import Service2 from "../../assets/images/slider/slider1.webp";
import Service3 from "../../assets/images/slider/slider2.webp";

export default function ServicesTwo() {
    return (
        <React.Fragment>
            <section className="newservice">
                <div className="row g-0 align-items-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="newserviceimgae">
                            <img src={Service1} alt="serive 1"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="newservicecontent">
                            <h2>Pop Up Experiential Classes and Events</h2>
                            <p>From a dance class in Madame Tussaud’s to unforgettable retreats get ready to be immersed in unforgettable experiences. Our community is perfect for those who want to inject life and excitement, establish new friendships and connections but are too shy to take that first step. We guarantee that after your first experience with us, you will come back for more.</p>
                            <div className="newservicebtn">
                                <Link to="/studio-locations/">Book Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="newservice secondservice">
                <div className="row g-0 align-items-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="newservicecontent">
                            <h2>Dance With Your Colleagues</h2>
                            <p>Struggling to connect your team members to work cohesively? Build a positive work culture with our highly personalised team building workshops, weekly classes or even dance performances for your DnD.</p>
                            <div className="newservicebtn">
                                <Link to="/team-building-workshops/">Book Now</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="newserviceimgae">
                            <img src={Service2} alt="serive 1"/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="newservice thirdservice">
                <div className="row g-0 align-items-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="newserviceimgae">
                            <img src={Service3} alt="serive 1"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="newservicecontent">
                            <h2>Join us as an Ambassador</h2>
                            <p>Have a passion and want to transform lives? Have a calling to leave that cubicle job to pursue a career in the dance, fitness or wellness realm. With over a decade of experience in the industry, start your journey with us as we guide you through every step. From starting your own brand to building a community to living a life of freedom and joy knowing that you are making a difference in someone’s life or even health.</p>
                            <div className="newservicebtn">
                                <Link to="/join-us/">Join us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}