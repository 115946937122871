import React, {useState} from "react";
import { PageMeta } from "../../Api/pagedata";
import {Helmet} from "react-helmet";
import "./pricing.css";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import { Link, useLocation } from "react-router-dom";

export default function Faq() {
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Pricing | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar />
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadcrumhead">
                        <h1>Pricing</h1>
                    </div>
                </div>
            </section>
            <section className="pricingarea">
                <div className="container">
                    <div className="pricinghead">
                        <h2>Casual or committed? We've got you covered.</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="pricingbox popularprice">
                                <h2>30 Credits Package</h2>
                                <div className="pricearea">
                                    <h3>$150.00</h3>
                                    <p>No Expiry</p>
                                </div>
                                <div className="buypackagebtn">
                                    <Link to="https://momence.com/m/286316" target="_blank">Buy 30 Credits Package</Link>
                                </div>
                                <div className="benifitboxs">
                                    <ul>
                                        <li>Package has no expiry</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="pricingbox semiprice">
                                <h2>60 Credits Package</h2>
                                <div className="pricearea">
                                    <h3>$300.00</h3>
                                    <p>No Expiry</p>
                                </div>
                                <div className="buypackagebtn">
                                    <Link to="https://momence.com/m/286313" target="_blank">Buy 60 Credits Package</Link>
                                </div>
                                <div className="benifitboxs">
                                    <ul>
                                        <li>Package has no expiry</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="pricingbox">
                                <h2>Gift Card</h2>
                                <div className="pricearea">
                                    <h3>Different Prices</h3>
                                </div>
                                <div className="buypackagebtn">
                                    <Link to="https://momence.com/gcc/9357" target="_blank">Buy Gift Card</Link>
                                </div>
                                <div className="benifitboxs">
                                    <ul>
                                        <li>Gift your girlfriend/ partner to experience our award winning classes. Prices are set according to our current class prices.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pricebottom">
                <div className="container">
                    <div className="pricebottomcotnent">
                        <h4>ENQUIRE WITH OUR INSTRUCTORS TO FIND OUT MORE ABOUT OUR MEMBERSHIP PRICES AND THE SAVINGS THAT ARE AWAITING YOU!</h4>
                        <p>*For all your membership FAQs click <Link to="/faq/">here.</Link></p>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}
