import React, {useState} from "react";
import { PageMeta } from "../../../Api/pagedata";
import {Helmet} from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import "./kidsdance.css";
import Navbar from "../../../Components/Navbar/navbar";
import Footer from "../../../Components/Footer/footer";
import Findus from "../../../Components/Findus/findus";
import Clietns from "../../../Components/Ourclient/ourclient";
import { Modal, Button } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { ReactComponent as Youtubeicon } from "../../../assets/images/youtubevideo.svg";

export default function Kidsdance(){
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState('');
    const handleClose = () => setIsOpen(false);
    const openModal = (id) => {
        setVideoId(id);
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
        setVideoId('');
    };
    const opts = {
        height: '500px',
        width: '100%',
        playerVars: {
            autoplay: 1, 
            rel: 0, 
        },
    };
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <React.Fragment>
            <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
            <Helmet>
                <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Kids Dance Classes and Parties | Dance Embassy'}</title>
                <meta name="description" content={PageMetas.metadescription || ''} />
                <meta name="keywords" content={PageMetas.metakeyword || ''} />
            </Helmet>
            <Navbar/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadcrumhead">
                            <h1>Kids Dance Classes and Parties</h1>
                        </div>
                    </div>
                </section>
                <section className="kidsdance">
                    <div className="container-fluid">
                        <div className="row g-0 align-items-center">
                            <div className="col-lg-6">
                                <div className="kidsvideo">
                                    <div className="getsocialbox">
                                        <img src="https://img.youtube.com/vi/Q_3aZ3gRt_A/0.jpg" alt="YouTube Thumbnail" onClick={() => openModal('Q_3aZ3gRt_A')} style={{ cursor: 'pointer' }} />
                                        <div className="videobtns" onClick={() => openModal('Q_3aZ3gRt_A')}><Youtubeicon/></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="kidescontent">
                                    <p>Kids will never forget their birthday parties. Create a memorable one for them by engaging our kids friendly crew from Dance Embassy.</p>
                                    <p>We will make sure that it’s fun and engaging and we throw in a complimentary photo and video session to encapsulate the dance party!</p>
                                    <p>You can let us know favourite songs of your kids and we will take care of the rest! Our qualified and kids friendly teachers have years of experience working with kids of all ages and most of them ask to join our kids classes right after. Clearly, they can’t get enough!</p>
                                    <p>We are happy to provide quotations for other entertainment needs like magician, balloon sculptors as we also work closely with a stellar events company. We know how overwhelming it can be to plan a kids party. We got your back!</p>
                                    <p>Prices start from $250 an hour with 1 instructor, music, photo and video taking.</p>
                                    <p>One can attest to the many wondrous benefits music and movement can bring to the development of a kid. We have, over the years, provided schools from Nursery to Colleges unique dance classes for kids. The kids we have taught not only pick up amazing dancing skills but also social skills and parents have raved about how their kids have been displaying happy and positive attitudes. Enquire within about our kids group classes or if you want to introduce our classes to your school as an ECA!</p>
                                    <div className="kidscta">
                                        <Link to="/contact-us/">Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal show={isOpen} onHide={closeModal} centered size="lg" dialogClassName="video-popup">
                    <Modal.Body>
                        <div className="videopopupclose">
                            <Button variant="close" onClick={handleClose} aria-label="Close"></Button>
                        </div>
                        {videoId && <YouTube videoId={videoId} opts={opts} />}
                    </Modal.Body>
                </Modal>
            <Findus/>
            <Clietns/>
            <Footer/>
        </React.Fragment>
    )
}